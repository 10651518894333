.add-client-container {
    padding: 20px;
}

.add-client-container h2 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.add-client-container h2 .icon {
    margin-right: 10px;
}

.add-client-container h3 {
    margin-bottom: 10px;
}

.client-form {
    display: flex;
    flex-direction: column;
}

.client-form label {
    margin-bottom: 10px;
}

.client-form input, .client-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.client-form .form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.form-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button {
    background-color: #e74c3c;
    color: white;
}

.cancel-button {
    background-color: #95a5a6;
    color: white;
}

.save-button {
    background-color: #3498db;
    color: white;
}