.overview-container {
    padding: 20px;
    color: #00046e;
}

.status-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.status-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3px;
    margin-bottom: 20px;
}

.status-card {
    flex: 1 1 calc(33.333% - 2px);
    background-color: #f8f9fa;
    padding: 20px;
    text-align: center;
    border: 3px solid #00046e;
    border-radius: 4px;
    box-sizing: border-box;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #00046e; 
}

.status-card p {
    margin: 0;
    font-weight: bold;
}

.participants-table {
    width: 100%;
    border-collapse: collapse;
}

.participants-table th, .participants-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.participants-table th {
    background-color: #f2f2f2;
}

.participants-table td.good {
    color: green;
}

.participants-table td.attention {
    color: red;
}

.participants-table td.suspended {
    color: gray;
}