.dashboard-container {
    display: flex;
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}
