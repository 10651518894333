.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.logo {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 30px;
}

.nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nav-menu a {
    padding: 10px 20px;
    text-decoration: none;
    color: #2c3e50;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.nav-menu a .icon {
    margin-right: 10px;
}

.nav-menu a.active-link {
    background-color: #3498db;
    color: white;
}

.logout-button {
    margin-top: auto;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
}