.manage-cycles-container {
    padding: 20px;
}

.manage-cycles-container h2 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.manage-cycles-container h2 .icon {
    margin-right: 10px;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    background-color: #f8f9fa;
    border: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.tab.active {
    border-bottom: 2px solid #3498db;
    font-weight: bold;
}

.content {
    margin-bottom: 20px;
}

.create-cycle-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.participant-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.participant-list li {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.participant-list li:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.participant-list .add-button {
    background: none;
    border: none;
    color: #3498db;
    cursor: pointer;
    font-size: 18px;
}

.selected-participant-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.selected-participant-list li {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selected-participant-list button {
    background: none;
    border: none;
    color: #e74c3c;
    cursor: pointer;
    font-size: 18px;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.form-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #95a5a6;
    color: white;
}

.save-button {
    background-color: #3498db;
    color: white;
}