.individual-client-container {
    padding: 20px;
}

.individual-client-container h2 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.individual-client-container h2 .icon {
    margin-right: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    height: 100px;
    resize: none;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.form-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #95a5a6;
    color: white;
}

.save-button {
    background-color: #3498db;
    color: white;
}