.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffffff;
}

.login-logo img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.login-title {
    font-size: 48px;
    color: #2c3e50;
    margin-bottom: 20px;
}

.login-input {
    width: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.login-button {
    width: 300px;
    padding: 10px;
    margin-top: 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.forgot-password {
    background: none;
    border: none;
    color: #3498db;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
}