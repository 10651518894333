.client-database-container {
    padding: 20px;
}

.client-database-container h2 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.client-database-container h2 .icon {
    margin-right: 10px;
}

.clients-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.clients-table th, .clients-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.clients-table th {
    background-color: #f2f2f2;
}