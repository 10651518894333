.override-status-container {
    padding: 20px;
}

.override-status-container h2 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.override-status-container h2 .icon {
    margin-right: 10px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.clients-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.clients-table th, .clients-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.clients-table th {
    background-color: #f2f2f2;
}

.status.good {
    color: green;
}

.status.attention {
    color: red;
}

.status.suspended {
    color: gray;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
}

.form-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #95a5a6;
    color: white;
}

.save-button {
    background-color: #3498db;
    color: white;
}