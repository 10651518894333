.settings-container {
    padding: 20px;
}

.settings-container h2 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.settings-container h2 .icon {
    margin-right: 10px;
}

.settings-container p {
    color: #999;
    font-style: italic;
}